<template>
  <div style="width: 100%; text-align: center; margin-top: 30px">
    {{ loginInfo }}
  </div>
</template>

<script>
import { setSessionStr } from "@/utils/session.js";

export default {
  data() {
    return {
      loginInfo: this.$t("lang.empty"),
    };
  },
  created() {
    localStorage.setItem("client", "app");
    localStorage.setItem("locale", "ZH");
    this.$i18n.locale = "ZH";
    document.title = this.$t("lang.apptitle");
  },
  mounted() {
    setSessionStr("ServerUrl", "https://www.baidu.com/");
    this.$router.replace({ path: "/query/index" });
  },
};
</script>
